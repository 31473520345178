<template>
  <div>
    <vxe-toolbar class="px-3" custom :refresh="{ query: getPoolList }">
      <template v-slot:buttons>
        <vxe-input
          v-model="filterName"
          type="search"
          placeholder="全文搜索"
          class="mr-1"
        ></vxe-input>
      </template>
    </vxe-toolbar>

    <vxe-table
      id="coreTable"
      row-id="id"
      ref="coreTable"
      :data="list"
      :border="false"
      resizable
      show-overflow
      show-header-overflow
      highlight-hover-row
      align="center"
      :loading="coreTableLoading"
      :column-config="{ resizable: true }"
      :height="tableHeight"
      size="small"
      :stripe="true"
      :sort-config="{ trigger: 'cell', multiple: true }"
      :scroll-y="{ mode: 'wheel', gt: 50, oSize: 30 }"
      :empty-render="{ name: 'NotData' }"
      :filter-config="{ showIcon: false }"
      :export-config="{}"
    >
      <vxe-column fixed="left" min-width="70" field="code" title="股票代码">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <a
              href="javascript:;"
              @click="
                $stockHelpers.openStockDetail({
                  stock_code: row.code,
                  stock_name: row.name,
                  code_type: row.type,
                })
              "
              class="text-xs font-weight-normal text-typo"
              >{{ row.code ? row.code : "-" }}</a
            >
          </div>
        </template>
      </vxe-column>

      <vxe-column fixed="left" min-width="80" field="name" title="股票名称">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <a
              href="javascript:;"
              @click="
                $stockHelpers.openStockDetail({
                  stock_code: row.code,
                  stock_name: row.name,
                  code_type: row.type,
                })
              "
              class="text-xs font-weight-normal text-typo"
              >{{ row.name ? row.name : "-" }}</a
            >
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="80"
        field="gains.applies_day"
        title="当日涨幅"
        sortable
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.gains.applies_day)">{{
              $stockHelpers.getPercent(row.gains.applies_day)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="70"
        field="gains.applies_five"
        title="5日涨幅"
        sortable
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.gains.applies_five)">{{
              $stockHelpers.getPercent(row.gains.applies_five)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="80"
        field="gains.applies_20"
        title="20日涨幅"
        sortable
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.gains.applies_20)">{{
              $stockHelpers.getPercent(row.gains.applies_20)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        min-width="80"
        field="gains.applies_60"
        title="60日涨幅"
        sortable
      >
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-end">
            <span :style="$stockHelpers.getColor(row.gains.applies_60)">{{
              $stockHelpers.getPercent(row.gains.applies_60)
            }}</span>
          </div>
        </template>
      </vxe-column>

      <vxe-column min-width="80" field="created_at" title="入池日期" sortable>
        <template #header="{ column }">
          <div style="word-wrap: break-word; white-space: pre-wrap">
            {{ column.title }}
          </div>
        </template>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.created_at }}
          </div>
        </template>
      </vxe-column>

      <vxe-column
        title="操作"
        type="seq"
        min-width="68"
        :resizable="false"
        show-overflow
      >
        <template #default="{ row }">
          <div>
            <a
              href="javascript:;"
              @click="deleteFromPool(row.code)"
              class="text-xs font-weight-normal text-typo"
              >删除</a
            >
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import StockPool from "@/api/stockPool.js";
import XEUtils from "xe-utils";

export default {
  name: "PoolTable",
  components: {},
  data() {
    return {
      coreTableLoading: false,
      tableHeight: "500px",
      filterName: "",
      poolList: [],
    };
  },
  computed: {
    list() {
      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      if (filterName) {
        const filterRE = new RegExp(filterName, "gi");
        const searchProps = ["name", "code", "pinyin"];
        const rest = this.poolList.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        return rest;
      }
      return this.poolList;
    },
  },
  mounted() {
    this.tableHeight = document.body.offsetHeight - 140 + "px";
    this.getPoolList();
    Bus.$on("refreshCoreStockPool", (e) => {
      this.getPoolList();
    });
  },
  methods: {
    getPoolList() {
      this.coreTableLoading = true;
      StockPool.getPoolList()
        .then((res) => {
          this.poolList = res.data;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.coreTableLoading = false;
        });
    },
    deleteFromPool(code) {
      this.$layer.confirm("确定要删除该项目吗？", (layerid) => {
        this.showLoading("删除中，请稍候");
        StockPool.deleteFromPool(code)
          .then((res) => {
            this.showToast("已成功删除！", "success", 5000);
            Bus.$emit("refreshCoreStockPool", true);
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },
  },
};
</script>
