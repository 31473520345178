<template>
  <v-container fluid class="px-1 py-0">
    <div class="d-flex justify-center" style="width: calc(100vw - 90px)">
      <div style="width: 1280px" class="d-flex">
        <v-card
          class="border-radius-md mt-3 py-2 mx-2 elevation-2"
          style="width: 900px"
        >
          <PoolTable></PoolTable>
        </v-card>
        <v-card
          class="border-radius-md mt-3 py-2 mx-2 elevation-2"
          style="width: 380px"
        >
          <div class="pa-4">
            <div>
              <div style="font-size: large" class="text-dark">使用说明</div>
              <div style="color: #777; font-size: 13px">
                1.右上角搜索股票，进入股票详情，可收藏到自选池里，方便后续查看。<br />
                2.回测后可查看回测历史。<br />
                3.在回测历史中可收藏回测参数。<br />
                4.收藏的回测参数可设为默认，在自选池中将收到该参数的交易信号。<br />
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import PoolTable from "@/components/Pool/PoolTable.vue";

export default {
  name: "Pool",
  components: { PoolTable },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
